<template>
  <div class="inline-flex flex-col">
    <div class="flex flex-row">
      <div class="flex flex-row items-start justify-start text-center gap-2">
        <div v-for="(item, index) in items" :key="`${index}-${item.time}`" class="flex flex-row gap-1">
          <span class="inline-flex -mt-1 -ml-1 items-center font-bold" v-if="index > 0">-</span>
          <div class="label-base rounded-sm shadow-none text-white m-0 self-start" :class="[{ 'w-12 py-0': !vinkjes, 'py-0.5': vinkjes }, item.background || '', labelClass]" @click="item.onClick">
            <i v-if="vinkjes" class="text-sm w-4" :class="{ 'fas fa-check': item?.time, 'fas fa-question': !item?.time }"></i>
            <span v-else>{{ item?.time ? timeString(item.time) : '?' }}</span>
          </div>
        </div>
        <div v-if="kalender" class="font-bold pt-0.5 inline-flex gap-2">
          <span class="text-blue-700">
            {{ store.getters.wagen(wagen_id || -1)?.nummerplaat || '?' }}
          </span>
          <span class="text-purple-700">
            {{ chiron_status ? chiron_status : '?' }}
          </span>
        </div>
      </div>
      <!-- class="font-bold pt-0.5 inline-flex flex-row items-center justify-start text-center" -->
    </div>
    <span v-if="isNoShow" class="bg-red-700 text-white rounded px-2 py-0 font-bold text-center opacity-90 w-64">
      NO SHOW / NIET GEREDEN
    </span>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import { timeString } from '@/functions/formatDate'
import { useStore } from 'vuex'

const props = defineProps({
  start: [String, Number, Date, Object],
  aangekomen: [String, Number, Date, Object],
  klant_ingestapt: [String, Number, Date, Object],
  klant_afgezet: [String, Number, Date, Object],
  einde: [String, Number, Date, Object],
  wagen_id: [String, Number],
  isNoShow: Boolean,
  chiron_status: String,
  labelClass: String,
  compact: Boolean,
  vinkjes: Boolean,
  alerts: Boolean,
  showAll: Boolean,
  kalender: Boolean,
})

const store = useStore()

const items = computed(() => {
  const result = [{
    time: props.start,
    background: 'bg-green-500',
    onClick: () => props.alerts && alert(`Shift gestart: ${props.start ? timeString(props.start) : '?'}`),
  }]

  if (props.showAll || (props.aangekomen && props.klant_ingestapt && props.klant_afgezet)) {
    result.push({
      time: props.aangekomen,
      background: 'bg-teal-500',
      onClick: () => props.alerts && alert(`Aangekomen op ophaallocatie/luchthaven: ${props.aangekomen ? timeString(props.aangekomen) : '?'}`),
    })
    result.push({
      time: props.klant_ingestapt,
      background: 'bg-purple-500',
      onClick: () => props.alerts && alert(`Klant ingestapt: ${props.klant_ingestapt ? timeString(props.klant_ingestapt) : '?'}`),
    })
    result.push({
      time: props.klant_afgezet,
      background: 'bg-orange-500',
      onClick: () => props.alerts && alert(`Klant afgezet: ${props.klant_afgezet ? timeString(props.klant_afgezet) : '?'}`),
    })
  }

  result.push({
    time: props.einde,
    background: 'bg-red-600',
    onClick: () => props.alerts && alert(`Klant afgezet: ${props.einde ? timeString(props.einde) : '?'}`),
  })

  return result.map((item) => ({
    ...item,
    background: item.time ? item.background : 'bg-gray-200 text-gray-500',
  }))
})
</script>
